import type { IdTokenClaims, SigninRedirectArgs } from 'oidc-client-ts';
import { config } from '../config';

export const logisticsTenant = {
    RIO_MAHLE_DEMO: 'rio-mahle-demo',
    SCANIA_CN: 'scania-cn',
    SCANIA_CN_DEV: 'scania-cn-dev',
    SCANIA_CN_PREPROD: 'scania-cn-preprod',
    SCANIA_CN_SANDBOX: 'scania-cn-sandbox',
    RIO_SCM_TEST: 'rio-scm-test',
    RIO_VW_TEST: 'rio-vw-test',
} as const;

export type LogisticsTenant = (typeof logisticsTenant)[keyof typeof logisticsTenant];

export const getLogisticsTenant = (): LogisticsTenant => {
    const subDomain = window.location.host.split('.')[0];
    switch (subDomain) {
        case 'rio-mahle-demo':
            return logisticsTenant.RIO_MAHLE_DEMO;
        case 'rio-vw-test':
            return logisticsTenant.RIO_VW_TEST;
        case 'rio-adam-cn-test':
            return logisticsTenant.RIO_SCM_TEST;
        case 'adam-cn-dev':
            return logisticsTenant.SCANIA_CN_DEV;
        case 'adam-cn-preprod':
            return logisticsTenant.SCANIA_CN_PREPROD;
        case 'adam-cn-sbox':
            return logisticsTenant.SCANIA_CN_SANDBOX;
        case 'adam-cn':
        default:
            return logisticsTenant.SCANIA_CN;
    }
};

export const getRedirectUri = (): string | undefined => {
    const currentLogisticsTenant = getLogisticsTenant();

    switch (currentLogisticsTenant) {
        case logisticsTenant.RIO_MAHLE_DEMO:
            return config.login.redirectUriRioMahleDemo;
        case logisticsTenant.RIO_VW_TEST:
            return config.login.redirectUriRioVwTest;
        case logisticsTenant.RIO_SCM_TEST:
            return config.login.redirectUriRioScaniaCnTest;
        case logisticsTenant.SCANIA_CN_DEV:
            return config.login.redirectUriDev;
        case logisticsTenant.SCANIA_CN_PREPROD:
            return config.login.redirectUriPreprod;
        case logisticsTenant.SCANIA_CN_SANDBOX:
            return config.login.redirectUriSandbox;
        case logisticsTenant.SCANIA_CN:
            return config.login.redirectUri;
    }
};

export const getSigninOptions = (currentLogisticsTenant: LogisticsTenant): SigninRedirectArgs => {
    switch (currentLogisticsTenant) {
        // if the idp parameter is provided as an extra query parameter, then the auth-server will redirect to the
        // corresponding IDP for login
        case logisticsTenant.SCANIA_CN_PREPROD:
            return { extraQueryParams: { idp: 'azure-scania-com-cn-preprod' } };
        case logisticsTenant.SCANIA_CN_DEV:
            return { extraQueryParams: { idp: 'azure-scania-com-cn-dev' } };
        case logisticsTenant.SCANIA_CN_SANDBOX:
            // TODO temporarily switched to RIO IDP for Key user testing
            // return { extraQueryParams: { idp: 'azure-scania-com-cn-sandbox' } };
            return {};
        case logisticsTenant.SCANIA_CN:
            return { extraQueryParams: { idp: 'azure-scania-com-cn-prod' } };
        case logisticsTenant.RIO_MAHLE_DEMO:
        case logisticsTenant.RIO_VW_TEST:
        case logisticsTenant.RIO_SCM_TEST:
            return {};
    }
};

export const logisticsTenantInToken = {
    RIO_MAHLE_DEMO: 'mahle.demo',
    SCANIA_CN: 'scania-cn.prod',
    SCANIA_CN_DEV: 'scania-cn.dev',
    SCANIA_CN_PREPROD: 'scania-cn.preprod',
    SCANIA_CN_SANDBOX: 'scania-cn.sandbox',
    RIO_SCANIA_CN_CAPABILITY_MONITORING: 'scania-cn.capability-monitoring',
    RIO_SCANIA_CN_TEST: 'scania-cn.rio-dev',
    RIO_SCM_TEST: 'rio-scm.test',
    RIO_VW_TEST: 'vw.test',
} as const;

export const isTenantValidForUrl = (toValidate: string | undefined): boolean => {
    const subDomain = window.location.host.split('.')[0];
    switch (subDomain) {
        case 'localhost:3000':
            return logisticsTenantInToken.RIO_SCANIA_CN_TEST === toValidate;
        case 'rio-mahle-demo':
            return logisticsTenantInToken.RIO_MAHLE_DEMO === toValidate;
        case 'rio-vw-test':
            return logisticsTenantInToken.RIO_VW_TEST === toValidate;
        case 'rio-adam-cn-test':
            return (
                logisticsTenantInToken.RIO_SCM_TEST === toValidate ||
                logisticsTenantInToken.RIO_SCANIA_CN_TEST === toValidate ||
                logisticsTenantInToken.RIO_SCANIA_CN_CAPABILITY_MONITORING === toValidate
            );
        case 'adam-cn-dev':
            return logisticsTenantInToken.SCANIA_CN_DEV === toValidate;
        case 'adam-cn-preprod':
            return logisticsTenantInToken.SCANIA_CN_PREPROD === toValidate;
        case 'adam-cn-sbox':
            return logisticsTenantInToken.SCANIA_CN_SANDBOX === toValidate;
        case 'adam-cn':
        default:
            return logisticsTenantInToken.SCANIA_CN === toValidate;
    }
};

export const getUrlForTenant = (input: string | undefined) => {
    switch (input) {
        case logisticsTenantInToken.RIO_MAHLE_DEMO:
            return 'https://rio-mahle-demo.scm.rio.cloud';
        case logisticsTenantInToken.RIO_VW_TEST:
            return 'https://rio-vw-test.scm.rio.cloud';
        case logisticsTenantInToken.SCANIA_CN_DEV:
            return 'https://adam-cn-dev.scm.rio.cloud';
        case logisticsTenantInToken.SCANIA_CN_PREPROD:
            return 'https://adam-cn-preprod.scm.rio.cloud';
        case logisticsTenantInToken.SCANIA_CN_SANDBOX:
            return 'https://adam-cn-sbox.scm.rio.cloud';
        case logisticsTenantInToken.RIO_SCANIA_CN_CAPABILITY_MONITORING:
        case logisticsTenantInToken.RIO_SCANIA_CN_TEST:
        case logisticsTenantInToken.RIO_SCM_TEST:
            return 'https://rio-adam-cn-test.scm.rio.cloud';
        case logisticsTenantInToken.SCANIA_CN:
            return 'https://adam-cn.scm.rio.cloud';
        default:
            return undefined;
    }
};

export const getLogisticsTenantFromClaims = (claims: IdTokenClaims | undefined) =>
    typeof claims?.logistics_tenant === 'string' ? claims.logistics_tenant : undefined;

export const mapToLogisticsTenant = (input: string | undefined) => {
    switch (input) {
        case logisticsTenantInToken.SCANIA_CN_DEV:
            return logisticsTenant.SCANIA_CN_DEV;
        case logisticsTenantInToken.SCANIA_CN_PREPROD:
            return logisticsTenant.SCANIA_CN_PREPROD;
        case logisticsTenantInToken.SCANIA_CN_SANDBOX:
            return logisticsTenant.SCANIA_CN_SANDBOX;
        case logisticsTenantInToken.RIO_SCANIA_CN_CAPABILITY_MONITORING:
        case logisticsTenantInToken.RIO_SCANIA_CN_TEST:
        case logisticsTenantInToken.RIO_SCM_TEST:
            return logisticsTenant.RIO_SCM_TEST;
        case logisticsTenantInToken.SCANIA_CN:
            return logisticsTenant.SCANIA_CN;
        case logisticsTenantInToken.RIO_MAHLE_DEMO:
            return logisticsTenant.RIO_MAHLE_DEMO;
        case logisticsTenantInToken.RIO_VW_TEST:
            return logisticsTenant.RIO_VW_TEST;
        default:
            return undefined;
    }
};
